// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.table-header-right{
    border-radius: 8px 8px 0 8px;
}

.table-rows-item {
    width: 120px;
    padding: 16px 12px;
}

.table-rows-item-last {
    width: 112px;
    padding: 16px 12px;
}

.table-rows-content-item {
    width: 120px;
    padding: 0 16px;
}

.contract-disabled {
    color: #C0CADD;
    padding: 3px 10px;
    border: 1px solid #DFE4EE;
    border-radius: 16px;
    background-color: #FCFCFD;
}

.contract-active {
    color: #4CAF50;
    padding: 3px 10px;
    border: 1px solid #C2E4C3;
    border-radius: 16px;
    background-color: #EDF7ED;
}`, "",{"version":3,"sources":["webpack://./src/components/App/Team/team.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;AAChC;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,yBAAyB;IACzB,mBAAmB;IACnB,yBAAyB;AAC7B;;AAEA;IACI,cAAc;IACd,iBAAiB;IACjB,yBAAyB;IACzB,mBAAmB;IACnB,yBAAyB;AAC7B","sourcesContent":[".table-header-right{\n    border-radius: 8px 8px 0 8px;\n}\n\n.table-rows-item {\n    width: 120px;\n    padding: 16px 12px;\n}\n\n.table-rows-item-last {\n    width: 112px;\n    padding: 16px 12px;\n}\n\n.table-rows-content-item {\n    width: 120px;\n    padding: 0 16px;\n}\n\n.contract-disabled {\n    color: #C0CADD;\n    padding: 3px 10px;\n    border: 1px solid #DFE4EE;\n    border-radius: 16px;\n    background-color: #FCFCFD;\n}\n\n.contract-active {\n    color: #4CAF50;\n    padding: 3px 10px;\n    border: 1px solid #C2E4C3;\n    border-radius: 16px;\n    background-color: #EDF7ED;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
