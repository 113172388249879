// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cards-block {
    background-color: #fff;
    border: 1px solid #E6E6E7;
    border-radius: 8px;
    padding: 24px 24px 32px 24px;
}

.cards-content-stroke {
    border: 1px solid #F2F3F3;
    border-radius: 8px;
    background-color: #FAFAFA;
    padding: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/Talent/talent.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,yBAAyB;IACzB,kBAAkB;IAClB,4BAA4B;AAChC;;AAEA;IACI,yBAAyB;IACzB,kBAAkB;IAClB,yBAAyB;IACzB,aAAa;AACjB","sourcesContent":[".cards-block {\n    background-color: #fff;\n    border: 1px solid #E6E6E7;\n    border-radius: 8px;\n    padding: 24px 24px 32px 24px;\n}\n\n.cards-content-stroke {\n    border: 1px solid #F2F3F3;\n    border-radius: 8px;\n    background-color: #FAFAFA;\n    padding: 16px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
