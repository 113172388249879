// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.menu .header {
    padding: 0 8px 0 12px;
}

.menu-item {
    height: 36px;
    align-items: center;
}

.menu-list-header {
    height: 24px;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Menu/menu.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,YAAY;IACZ,uBAAuB;AAC3B","sourcesContent":[".menu .header {\n    padding: 0 8px 0 12px;\n}\n\n.menu-item {\n    height: 36px;\n    align-items: center;\n}\n\n.menu-list-header {\n    height: 24px;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
